var MainNav = /** @class */ (function () {
    function MainNav(element) {
        this.element = element;
        this.init();
    }
    MainNav.prototype.init = function () {
        var _this = this;
        this.element.querySelectorAll('li').forEach(function (item) {
            var subMenu = item.querySelector('.sub-menu');
            item.classList.add('relative', 'had-sub-menu');
            if (subMenu) {
                var toggle_1 = createSubMenuToggle();
                item.appendChild(toggle_1);
                toggle_1.addEventListener('click', function () { return _this.toggleSubMenu(toggle_1); });
            }
        });
    };
    MainNav.prototype.toggleSubMenu = function (element) {
        console.log(element, element.closest('li'));
        element.closest('li').querySelector('.sub-menu').classList.add('block', '-open');
    };
    return MainNav;
}());
export default MainNav;
var createSubMenuToggle = function () {
    var element = document.createElement('span');
    element.classList.add('absolute', 'submenu-toggle', 'cursor-pointer');
    element.innerText = '+';
    return element;
};
