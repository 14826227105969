import MainNav from "./components/main-nav";
document.addEventListener('DOMContentLoaded', function () {
    var Navigation = new MainMenu(document.querySelector('#main-navigation'));
    var NavigationToggler = new Hamburger(document.querySelector('.hamburger'), Navigation);
    document.querySelectorAll('[data-loaded-classes]').forEach(function (el) {
        el.dataset.loadedClasses.toString().split(' ').forEach(function (className) {
            el.classList.add(className);
        });
    });
    var menu = document.querySelector('#menu-main');
    if (menu) {
        new MainNav(menu);
    }
});
document.querySelectorAll('img[data-setBgOnLoaded').forEach(function (img) {
    img.parentElement.querySelector('.ambient-background').style.backgroundImage = "url(" + img.src + ")";
    img.addEventListener('load', function () {
        img.parentElement.querySelector('.ambient-background').style.backgroundImage = "url(" + img.src + ")";
        img.classList.add('md:hidden');
    });
});
var MainMenu = /** @class */ (function () {
    function MainMenu(element) {
        this.element = element;
    }
    MainMenu.prototype.open = function () {
        this.element.classList.add('-open');
    };
    MainMenu.prototype.close = function () {
        this.element.classList.remove('-open');
    };
    return MainMenu;
}());
var Hamburger = /** @class */ (function () {
    function Hamburger(element, menu) {
        this.element = element;
        this.menu = menu;
        this.init();
    }
    Hamburger.prototype.init = function () {
        var _this = this;
        this.element.addEventListener('click', function () {
            _this.toggle();
        });
    };
    Hamburger.prototype.toggle = function () {
        this.element.classList.contains('open')
            ? this.close()
            : this.open();
    };
    Hamburger.prototype.open = function () {
        this.element.classList.add('open');
        this.menu.open();
    };
    Hamburger.prototype.close = function () {
        this.element.classList.remove('open');
        this.menu.close();
    };
    return Hamburger;
}());
